.activation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.activation-modal-dialog {
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  padding: 100px;
}

.activation-modal-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-top: 2vh;
}

.activation-modal-message {
  font-size: 22px;
  text-align: center;
  color: #333;
  direction: rtl;
  margin-bottom: 5vh;
}

.activation-modal-button {
  margin: 0 auto;
  display: block;
  width: 120px;
  font-size: 20px;
}

.activation-modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}
