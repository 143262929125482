.page-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  background-color: hsl(0, 0%, 84%);
  flex: 1;
  padding: 40px;
  margin: 20px;
  width: 50%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: right;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logout-button {
  color: red;
  border: none;
  background-color: transparent;
  transition: color 0.3s;
  border-radius: 50%;
  padding: 10px;
}

.logout-button.hovered {
  color: darkred;
}

.close-button {
  font-size: 2rem; /* Adjust the size as needed */
}

.rocket-icon {
  font-size: 32px;
  margin-right: 10px;
}

.text {
  font-size: 24px;
  color: black;
}

.dashboard-nav {
  display: flex;
  justify-content: flex-end;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  text-align: right;
}

.dashboard-nav .nav-link {
  color: #333;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
  text-align: right;
}

.dashboard-nav .nav-link.active {
  background-color: #4cd964;
  color: white;
}

.dashboard-content {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: right;
}

.dashboard-content h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: right;
}

.event-card {
  background-color: #52a0e9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.eventFormHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventFormInput {
  text-align: right;
  direction: rtl;
}

.eventFormFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.eventFormHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.eventFormHeader .close-button {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 20px;
}

.eventsHeaderContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.coachCard {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: yellow;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
}

.coachDetails {
  flex-grow: 1;
}

.coachName {
  margin-bottom: 5px;
  font-weight: bold;
}

.coachPhoneNumber {
  margin-bottom: 5px;
}

.coachActions {
  display: flex;
  flex-direction: row;
}

.edit-button,
.delete-button {
  margin-left: 5px;
}

.close-button {
  font-size: 2rem;
}

.hebrewText {
  direction: rtl;
  text-align: right;
}

.hebrewHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
.groupsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.groupCard {
  padding: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  direction: rtl;
  background-color: rgb(144, 234, 144);
  border-radius: 5px;
}

.groupName {
  font-weight: bold;
}

.groupClubName {
  margin-top: 5px;
}

.groupCoach {
  margin-top: 5px;
}

.practiceDay {
  margin-top: 10px;
}

.day {
  font-weight: bold;
}

.hours {
  margin-top: 5px;
}

.groupFormModal .toggleDaysContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.groupFormModal .toggleDayButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.groupFormModal .toggleDayButton.active {
  background-color: #007bff;
  color: #fff;
}

.groupFormHeader {
  display: flex;
  justify-content: space-between;
}

.groupFormFieldContainer {
  margin-bottom: 20px;
  text-align: right;
}

.groupFormInput {
  width: 100%;
}

.toggleDaysContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.toggleDayButton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.toggleDayButton.active {
  background-color: #007bff;
  color: #fff;
}

.timeRangeContainer {
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.groupsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.groupCard {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.groupCard.expanded {
  background-color: #f0f0f0;
}

.groupHeader {
  display: flex;
  flex-direction: column;
}

.groupName {
  font-weight: bold;
}

.groupClubName {
  margin-top: 5px;
}

.groupCoach {
  margin-top: 5px;
}

.practiceDay {
  margin-top: 10px;
}

.day {
  font-weight: bold;
}

.hours {
  margin-top: 5px;
}

.helpHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}
