.event-card {
  background-color: #52a0e9;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  color: white;
}

.event-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.event-header h4 {
  margin: 0; /* Update this line */
  order: 1; /* Add this line */
}

.expand-button {
  color: white;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 10px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  order: 2;
}

.event-details {
  margin-top: 10px;
}

.footerContainer {
  display: flex;
  width: 12rem;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.hebrewText {
  direction: rtl;
  text-align: right;
}

.hebrewHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
