@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@600&family=Open+Sans:wght@700&display=swap");

.UIContainer {
  display: flex;
  height: 100%;
  min-height: max-content;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

body {
  min-width: 400px;
  min-height: 80%;
  height: 100%;
  max-height: 100%;
}

.WelcomeContainer {
  background-color: #39b54a;
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  min-height: 800px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.spinnerContainer {
  width: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.beltPicker {
  width: 82%;
  margin-top: 2vh;
  color: black;
  text-align: right;
  font-family: "Open Sans";
}

.progressbarContainer {
  margin-top: 5%;
  width: 80%;
  justify-content: flex-start;
}

.backButtonContainer {
  width: 80%;
  margin-top: 1vh;
}
.backButtonStyle {
  padding-right: 2vh;
  padding-left: 2vh;
}

.paddingTop {
  padding-top: 5vh;
}
.paddingBottom {
  padding-bottom: 5vh;
}

.contentContainer {
  color: white;
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2vh;
  margin-bottom: 20vh;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
}

.notfoundContainer {
  flex: 1;
}

.formStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formField {
  text-align: right;
}

.imagePicker {
  width: 85%;
  margin-top: 1vh;
}

.imagePreview {
  width: 30%;
  min-width: 5vh;
  max-width: 20vh;
  padding-bottom: 2vh;
  border-radius: 100%;
}

.dateFields {
  width: 95px;
  text-align: center;
  direction: rtl;
}

.detailsForm {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 2vh;
  min-height: fit-content;
  width: 100%;
}

.dateForm {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 320px;
  margin-top: 1vh;
}

.genderForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.label {
  margin-top: 3vh;
  margin-bottom: 1vh;
}
.title {
  margin-bottom: 5vh;
}

.weightField {
  width: 82%;
  margin-bottom: 2v;
}

.namesForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 15vh;
  width: 55%;
  direction: rtl;
}

.parentField {
  margin-top: 1vh;
  width: 250px;
  margin-bottom: 1vh;
  direction: rtl;
}

.approveContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.proccessFinishedContainer {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.studentDetails {
  background-color: green;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 2vh;
  margin-bottom: 2vh;
  border-radius: 20px;
  align-items: stretch;
}

.studentWrittenDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4vh;
  margin-left: 4vh;
}

.studentWrittenFields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 4vh;
  margin-left: 4vh;
}

.imagePreviewAAprove {
  width: 40%;
  min-width: 5vh;
  max-width: 30vh;
  border-radius: 50%;
  margin: 1vh;
}

.days {
  direction: rtl;
}

.termsAgreeContainer {
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  max-width: 60vh;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
}

.parentsDetails {
  background-color: green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2vh;
  margin-bottom: 2vh;
  border-radius: 20px;
}

.mediumText {
  font-size: 18px;
}

.phoneVerifyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
}

.clothingSizesContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  width: 82%;
}
.shirtOrPantsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 1vh;
  padding-bottom: 1vh;
}

.shirtOrPantsPicker {
  color: black;
  text-align: right;
  font-family: "Open Sans";
  justify-content: center;
  align-items: center;
  width: 13vh;
}

.IDandAddressForm {
  width: 82%;
  margin-bottom: 3vh;
}

.IDnumberInput {
  margin-bottom: 2vh;
}

.detailsRow {
  display: flex;
  border-bottom: white;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vh;
}

.newtext {
  font-family: "Open Sans";
  color: white;
  direction: rtl;
  margin-top: 0.3vh;
  margin-bottom: 0.3vh;
  font-size: 22px;
}

.newsmallerText {
  font-size: 18px;
}

.newmediumText {
  font-size: 20px;
}

.newblackText {
  color: black;
}

.newextraSmallerText {
  direction: rtl;
  text-align: right;
  font-size: 12px;
  padding-right: 10px;
}

.newbiggerText {
  font-size: 26px;
}

.checkBox {
  margin-left: 2vh;
}

.addressDisplay {
  max-width: 100px;
}
.StartedButton {
  margin-top: 2vh;
}
