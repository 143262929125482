.modalHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  font-size: 2rem;
}

.marginRight {
  margin-right: 2rem;
}
